import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';

import { Flex, Icon, Stack, Text } from '@chakra-ui/react';

import apiGateway from '../../../../shared/apis/gateway';
import Checkbox from '../../../../shared/components/Form/Checkbox';
import PaymentStatusBadge from '../../../../shared/components/List/PaymentStatusBadge';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../../shared/utils/DateUtils';

const OrderIndex: React.FC = () => {
    const { setTitle } = useLayout();

    const [orders, setOrders] = useState([] as any);

    const FIELDS = [
        {
            name: '#',
            maxWidth: '80px'
        },
        {
            name: 'Vendedor',
            maxWidth: '100%'
        },
        {
            name: 'Valor Total',
            maxWidth: '80%'
        },
        {
            name: 'Status',
            maxWidth: '80%'
        },
        {
            name: 'Criado em',
            maxWidth: '160px'
        },
        {
            name: 'Ações',
            maxWidth: '80px'
        }
    ];

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleChangeSelectedItem = useCallback((value: string) => {
        setSelectedItems(oldState => {
            let list = oldState;

            if (!oldState.includes(value)) {
                list.push(value);
            } else {
                list = list.filter(item => item !== value);
            }

            return [...list];
        });
    }, []);

    const handleLoadItems = useCallback(() => {
        apiGateway.get('/orders').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) setOrders(data);
        });
    }, []);

    const handleDeleteSelectedItem = useCallback(async () => {
        if (selectedItems.length > 0) {
            const { status } = await apiGateway.delete('/orders', {
                params: {
                    ids: selectedItems
                },
                paramsSerializer: params => {
                    return qs.stringify(params);
                }
            });

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setSelectedItems([]);
                handleLoadItems();
            }
        }
    }, [selectedItems, handleLoadItems]);

    useEffect(() => {
        setTitle('Marketplace / Pedidos / Listagem');
        handleLoadItems();
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Stack direction={'row'} width="100%" mb="16px" spacing={4}>
                {selectedItems.length > 0 && (
                    <Flex
                        backgroundColor="red.500"
                        width="40px"
                        height="32px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        onClick={() => handleDeleteSelectedItem()}
                        title="Apagar Registros"
                    >
                        <Icon as={BiTrash} fontSize="18px" />
                    </Flex>
                )}
            </Stack>

            <Flex
                width="100%"
                height="100%"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                flexDirection="column"
            >
                <Flex
                    width="100%"
                    height="32px"
                    fontWeight="500"
                    fontSize="14px"
                    color="blue.800"
                    alignItems="center"
                    justifyContent="space-between"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                >
                    {FIELDS.map(field => (
                        <Flex
                            key={field.name}
                            width="100%"
                            maxWidth={field.maxWidth}
                            justifyContent="center"
                        >
                            <Text>{field.name}</Text>
                        </Flex>
                    ))}
                </Flex>

                <Flex width="100%" flexDirection="column">
                    {orders.map((order, index) => (
                        <Flex
                            key={order.id}
                            width="100%"
                            height="40px"
                            fontSize="14px"
                            color="blue.800"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                index % 2 === 0 ? 'gray.300' : 'gray.100'
                            }
                            lineHeight="16px"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[0].maxWidth}
                            >
                                <Checkbox
                                    onChange={() =>
                                        handleChangeSelectedItem(order.id)
                                    }
                                    isChecked={selectedItems.includes(order.id)}
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[1].maxWidth}
                            >
                                <Text>{order.seller.commerce.name}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[2].maxWidth}
                            >
                                <Text>
                                    {CurrencyUtils.numberToCurrency(
                                        order.total_value
                                    )}
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[3].maxWidth}
                            >
                                <PaymentStatusBadge value={order.status} />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[4].maxWidth}
                            >
                                <Text>
                                    {DateUtils.format(order.created_at)}
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[5].maxWidth}
                            ></Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default OrderIndex;
