import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Box,
    Image,
    Heading,
    Text,
    Button,
    useToast
} from '@chakra-ui/react';
import { Form } from '@unform/web';

import logo from '../../../assets/images/logo.png';
import Checkbox from '../../../shared/components/Form/Checkbox';
import Input from '../../../shared/components/Form/Input';
import { useLayout } from '../../../shared/hooks/layout';

type DataSubmit = {
    email: string;
    password: string;
};

const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    const { setUser } = useLayout();
    const toast = useToast();

    const handleToForgotPassword = useCallback(() => {
        navigate('/forgot-password');
    }, [navigate]);

    const handleSubmitLogin = useCallback(
        async (dataSubmit: DataSubmit) => {
            const { email, password } = dataSubmit;

            if (
                email === 'alexandre@w2psolution.com.br' &&
                password === process.env.REACT_APP_API_PASSWORD
            ) {
                localStorage.setItem(
                    '@admin:marketplace',
                    JSON.stringify({
                        email,
                        password
                    })
                );

                setUser({
                    email,
                    password
                });

                navigate('/dashboard');
            } else {
                toast({
                    title: 'Login Invalido',
                    description: '',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }
        },
        [navigate, LoginPage]
    );

    return (
        <Flex width="100%" height="100vh">
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="green.500"
                height="100%"
            >
                <Box px="160px">
                    <Image src={logo} alt="Logo E-Florista" />
                </Box>
            </Flex>
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <Form
                    onSubmit={handleSubmitLogin}
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        width="100%"
                        flexDirection="column"
                        p="98px"
                        fontWeight="500"
                        color="green.500"
                    >
                        <Heading fontSize="24px" textAlign="center">
                            Faça seu login
                        </Heading>

                        <Input
                            name="email"
                            size="md"
                            placeholder="Seu email"
                            mt="24px"
                        />
                        <Input
                            name="password"
                            typeInput="password"
                            size="md"
                            placeholder="Sua senha"
                        />

                        <Flex justifyContent="space-between">
                            <Box width="50%">
                                <Checkbox name="remember_password" size="sm">
                                    Lembrar senha
                                </Checkbox>

                                <Box
                                    onClick={() => handleToForgotPassword()}
                                    _hover={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Text fontSize="12px" color="gray.600">
                                        Esqueci minha senha
                                    </Text>
                                </Box>
                            </Box>

                            <Flex width="50%" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    width="100%"
                                    maxWidth="120px"
                                    fontSize="16px"
                                    py="8px"
                                    color="white"
                                    backgroundColor="green.500"
                                    _hover={{
                                        backgroundColor: 'green.500'
                                    }}
                                >
                                    Entrar
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Form>
            </Flex>
        </Flex>
    );
};

export default LoginPage;
