import React, { useRef, useEffect, memo } from 'react';

import {
    Flex,
    Box,
    Select as ChakraSelect,
    Text,
    SelectProps
} from '@chakra-ui/react';
import { useField } from '@unform/core';

interface IProps extends SelectProps {
    name: string;
    label?: string;
    isRequired?: boolean;
    maxWidth?: string;
    mb?: string | number;
}

const Select: React.FC<IProps> = ({
    name,
    label = null,
    children,
    isRequired,
    maxWidth,
    mb = '16px',
    width = '100&',
    ...rest
}) => {
    const inputRef = useRef<HTMLSelectElement>(null);

    const { fieldName, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },
            clearValue(ref: any) {
                ref.setInputValue('');
            }
        });
    }, [fieldName, registerField]);

    return (
        <Box width={width} maxWidth={maxWidth} fontWeight="500" mb={mb}>
            {label && (
                <Flex
                    width="100%"
                    textAlign="center"
                    fontSize="14px"
                    fontWeight="400"
                >
                    <Text>{label}</Text>
                    {isRequired && (
                        <Text ml="4px" color="red.500">
                            *
                        </Text>
                    )}
                </Flex>
            )}

            <ChakraSelect
                ref={inputRef}
                _focus={{
                    outline: 'none'
                }}
                name={name}
                size="sm"
                borderRadius="2px"
                boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                isInvalid={!!error}
                {...rest}
                focusBorderColor="none"
            >
                {children}
            </ChakraSelect>
        </Box>
    );
};

export default memo(Select);
