import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FiTrash2 } from 'react-icons/fi';

import { Flex, Box, Image } from '@chakra-ui/react';

import FileUpload from '../FileUpload';

interface IProps {
    cityId: string;
    defaultImage?: any;
}

const UploadCityImage: React.FC<IProps> = ({
    cityId,
    defaultImage = undefined
}) => {
    const [uploadedImage, setUploadedImage] = useState(null as any);

    const onUploadImage = useCallback(imageUploaded => {
        setUploadedImage(imageUploaded);
    }, []);

    const onRemoveImage = useCallback(() => {
        setUploadedImage(null);
    }, []);

    useEffect(() => {
        if (defaultImage) {
            setUploadedImage({
                uploaded: true,
                preview: defaultImage
            });
        }
    }, [defaultImage]);

    return !uploadedImage ? (
        <Flex width="100%" height="100%">
            <FileUpload cityId={cityId} setUploadedImage={onUploadImage} />
        </Flex>
    ) : (
        <Flex
            flexDirection="column"
            alignItems="center"
            mr="16px"
            position="relative"
        >
            <Flex
                mt="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="200px"
                height="160px"
                _hover={{
                    cursor: 'pointer',
                    color: 'green.500'
                }}
            >
                <Flex
                    width="100%"
                    height="160px"
                    alignItems="center"
                    justifyContent="center"
                >
                    {!uploadedImage.uploaded && !uploadedImage.error && (
                        <CircularProgressbar
                            styles={{
                                root: { width: 48 },
                                path: { stroke: '#1EBD94' }
                            }}
                            strokeWidth={10}
                            value={uploadedImage.progress}
                        />
                    )}

                    {uploadedImage.uploaded && (
                        <Image height="160px" src={uploadedImage.preview} />
                    )}
                </Flex>
            </Flex>

            <Box
                p="4px"
                backgroundColor="red.500"
                color="white"
                borderRadius="2px"
                position="absolute"
                top="0px"
                right="-10px"
                title="Excluir imagem"
                cursor="pointer"
                onClick={() => onRemoveImage()}
            >
                <FiTrash2 size={16} />
            </Box>
        </Flex>
    );
};

export default UploadCityImage;
