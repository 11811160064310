import React from 'react';
import { Routes as RoutesDOM, Route } from 'react-router-dom';

import LoginPage from '../../modules/auth/pages';
import CommerceIndex from '../../modules/commerce/pages';
import CommerceEdit from '../../modules/commerce/pages/edit';
import CommerceRegister from '../../modules/commerce/pages/register';
import CategoryIndex from '../../modules/marketplace/pages/categories';
import CategoryEdit from '../../modules/marketplace/pages/categories/edit';
import CategoryRegister from '../../modules/marketplace/pages/categories/register';
import CitiesIndex from '../../modules/marketplace/pages/cities';
import CityEdit from '../../modules/marketplace/pages/cities/edit';
import OrderIndex from '../../modules/marketplace/pages/orders';
import ProductIndex from '../../modules/marketplace/pages/products';
import SellerIndex from '../../modules/marketplace/pages/sellers';
import SellerEdit from '../../modules/marketplace/pages/sellers/edit';
import SellerRegister from '../../modules/marketplace/pages/sellers/register';
import LayoutPanel from '../components/Layout/LayoutPanel';
import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => (
    <RoutesDOM>
        <Route path="/" element={<LayoutPanel component={<Dashboard />} />} />

        <Route
            path="/login"
            element={<LayoutPanel component={<LoginPage />} />}
        />

        <Route
            path="/dashboard"
            element={<LayoutPanel component={<Dashboard />} />}
        />

        <Route
            path="/commerces"
            element={<LayoutPanel component={<CommerceIndex />} />}
        />

        <Route
            path="/commerces/new"
            element={<LayoutPanel component={<CommerceRegister />} />}
        />

        <Route
            path="/commerces/:id"
            element={<LayoutPanel component={<CommerceEdit />} />}
        />

        <Route
            path="/marketplace/categories"
            element={<LayoutPanel component={<CategoryIndex />} />}
        />

        <Route
            path="/marketplace/categories/new"
            element={<LayoutPanel component={<CategoryRegister />} />}
        />

        <Route
            path="/marketplace/categories/:id"
            element={<LayoutPanel component={<CategoryEdit />} />}
        />

        <Route
            path="/marketplace/products"
            element={<LayoutPanel component={<ProductIndex />} />}
        />

        <Route
            path="/marketplace/sellers"
            element={<LayoutPanel component={<SellerIndex />} />}
        />

        <Route
            path="/marketplace/sellers/new"
            element={<LayoutPanel component={<SellerRegister />} />}
        />

        <Route
            path="/marketplace/sellers/:id"
            element={<LayoutPanel component={<SellerEdit />} />}
        />

        <Route
            path="/marketplace/orders"
            element={<LayoutPanel component={<OrderIndex />} />}
        />

        <Route
            path="/marketplace/cities"
            element={<LayoutPanel component={<CitiesIndex />} />}
        />

        <Route
            path="/marketplace/cities/:slug"
            element={<LayoutPanel component={<CityEdit />} />}
        />
    </RoutesDOM>
);

export default Routes;
