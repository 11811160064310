import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { FiAlertTriangle, FiCamera, FiCheck } from 'react-icons/fi';

import { Flex, Text } from '@chakra-ui/react';

import apiGateway from '../../../../../../shared/apis/gateway';

interface IProps {
    cityId: string;
    setUploadedImage: any;
    width?: any;
    height?: any;
}

const FileUpload: React.FC<IProps> = ({ cityId, setUploadedImage }) => {
    const handleOnUploadImage = useCallback(
        async (file: any) => {
            try {
                const image = {
                    key: Math.random().toString(36).substr(2, 9),
                    id: null,
                    file: file[0],
                    name: file[0].name,
                    preview: URL.createObjectURL(file[0]),
                    progress: 0,
                    uploaded: false,
                    error: false,
                    url: null,
                    is_cover: false
                };

                setUploadedImage(image);

                const data = new FormData();
                data.append('files', image.file, image.name);

                const response = await apiGateway.patch(
                    `/marketplace/cities/${cityId}/logo`,
                    data,
                    {
                        onUploadProgress: e => {
                            const progress = parseInt(
                                Math.round(
                                    (e.loaded * 100) / e.total
                                ).toString(),
                                10
                            );

                            setUploadedImage({
                                ...image,
                                progress
                            });
                        }
                    }
                );

                setUploadedImage({
                    ...image,
                    id: response.data[0],
                    uploaded: true
                });
            } catch (error) {
                console.log('log', error);
                setUploadedImage(oldState => {
                    return {
                        ...oldState,
                        error: true
                    };
                });
            }
        },
        [setUploadedImage, cityId]
    );

    return (
        <Dropzone onDropAccepted={handleOnUploadImage}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <Flex
                    width="100%"
                    height={'160px'}
                    flexDirection="column"
                    alignItems="center"
                    border="2px dashed"
                    {...getRootProps()}
                    outline="none"
                >
                    <input {...getInputProps()} />
                    <Flex
                        display="flex"
                        width="100%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        borderColor="gray.600"
                        _hover={{
                            cursor: 'pointer'
                        }}
                    >
                        {!isDragActive && !isDragReject && (
                            <>
                                <FiCamera size={40} />
                                <Text fontSize="14px" fontWeight="500" mt="8px">
                                    Arraste ou clique aqui
                                </Text>
                            </>
                        )}

                        {isDragActive && !isDragReject && (
                            <Flex
                                flexDirection="column"
                                color="green.500"
                                alignItems="center"
                            >
                                <FiCheck size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Solte o arquivo aqui
                                </Text>
                            </Flex>
                        )}

                        {isDragReject && (
                            <Flex
                                flexDirection="column"
                                color="pink.500"
                                alignItems="center"
                            >
                                <FiAlertTriangle size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Arquivo não suportado
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            )}
        </Dropzone>
    );
};

export default FileUpload;
