import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { BiEdit, BiListPlus, BiTrash } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import { Flex, Icon, Stack, Text } from '@chakra-ui/react';

import apiGateway from '../../../../shared/apis/gateway';
import Checkbox from '../../../../shared/components/Form/Checkbox';
import EnableBadge from '../../../../shared/components/List/EnableBadge';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import DateUtils from '../../../../shared/utils/DateUtils';

const CategoryIndex: React.FC = () => {
    const { setTitle } = useLayout();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([] as any);

    const FIELDS = [
        {
            name: '#',
            maxWidth: '80px'
        },
        {
            name: 'Categoria',
            maxWidth: '100%'
        },
        {
            name: 'Categoria Pai',
            maxWidth: '80%'
        },
        {
            name: 'Status',
            maxWidth: '80%'
        },
        {
            name: 'Criado em',
            maxWidth: '160px'
        },
        {
            name: 'Ações',
            maxWidth: '80px'
        }
    ];

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleChangeSelectedItem = useCallback((value: string) => {
        setSelectedItems(oldState => {
            let list = oldState;

            if (!oldState.includes(value)) {
                list.push(value);
            } else {
                list = list.filter(item => item !== value);
            }

            return [...list];
        });
    }, []);

    const handleLoadItems = useCallback(() => {
        apiGateway.get('/marketplace/categories').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) setCategories(data);
        });
    }, []);

    const handleDeleteSelectedItem = useCallback(async () => {
        if (selectedItems.length > 0) {
            const { status } = await apiGateway.delete(
                '/marketplace/categories',
                {
                    params: {
                        ids: selectedItems
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params);
                    }
                }
            );

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setSelectedItems([]);
                handleLoadItems();
            }
        }
    }, [selectedItems, handleLoadItems]);

    useEffect(() => {
        setTitle('Marketplace / Categorias / Listagem');
        handleLoadItems();
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Stack direction={'row'} width="100%" mb="16px" spacing={4}>
                <Flex
                    backgroundColor="green.500"
                    width="96px"
                    height="32px"
                    borderRadius="2px"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    cursor="pointer"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                    onClick={() => navigate('/marketplace/categories/new')}
                >
                    <Icon as={BiListPlus} fontSize="22px" mr="4px" />
                    <Text fontSize="14px" fontWeight="500">
                        Novo
                    </Text>
                </Flex>

                {selectedItems.length > 0 && (
                    <Flex
                        backgroundColor="red.500"
                        width="40px"
                        height="32px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        onClick={() => handleDeleteSelectedItem()}
                        title="Apagar Registros"
                    >
                        <Icon as={BiTrash} fontSize="18px" />
                    </Flex>
                )}
            </Stack>

            <Flex
                width="100%"
                height="100%"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                flexDirection="column"
            >
                <Flex
                    width="100%"
                    height="32px"
                    fontWeight="500"
                    fontSize="14px"
                    color="blue.800"
                    alignItems="center"
                    justifyContent="space-between"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                >
                    {FIELDS.map(field => (
                        <Flex
                            key={field.name}
                            width="100%"
                            maxWidth={field.maxWidth}
                            justifyContent="center"
                        >
                            <Text>{field.name}</Text>
                        </Flex>
                    ))}
                </Flex>

                <Flex width="100%" flexDirection="column">
                    {categories.map((category, index) => (
                        <Flex
                            key={category.id}
                            width="100%"
                            height="40px"
                            fontSize="14px"
                            color="blue.800"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                index % 2 === 0 ? 'gray.300' : 'gray.100'
                            }
                            lineHeight="16px"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[0].maxWidth}
                            >
                                <Checkbox
                                    onChange={() =>
                                        handleChangeSelectedItem(category.id)
                                    }
                                    isChecked={selectedItems.includes(
                                        category.id
                                    )}
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[1].maxWidth}
                            >
                                <Text>{category.name}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[2].maxWidth}
                            >
                                <Text>{category?.parent?.name || ''}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[3].maxWidth}
                            >
                                <EnableBadge value={category.is_enable} />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[4].maxWidth}
                            >
                                <Text>
                                    {DateUtils.format(category.created_at)}
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[5].maxWidth}
                            >
                                <Flex
                                    backgroundColor={'blue.500'}
                                    color="gray.200"
                                    p="4px"
                                    borderRadius="2px"
                                    title="Editar"
                                    cursor="pointer"
                                    onClick={() =>
                                        navigate(
                                            `/marketplace/categories/${category.id}`
                                        )
                                    }
                                >
                                    <Icon as={BiEdit} fontSize="16px" />
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CategoryIndex;
