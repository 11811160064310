import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    z-index: 1600;
    span {
        font-size: 12px;
        min-width: 200px;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        opacity: 0;
        transition: opacity 0.4s;
        visibility: hidden;
        position: absolute;
        bottom: calc(100% + 8px);
        left: -90px;
    }
    &:hover span {
        opacity: 1;
        visibility: visible;
    }
`;

export default Container;
