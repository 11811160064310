import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import {
    Flex,
    Icon,
    Radio,
    RadioGroup,
    Spinner,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiGateway, { apiEflorista } from '../../../../shared/apis/gateway';
import Form from '../../../../shared/components/Form';
import Select from '../../../../shared/components/Form/Select';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const SellerRegister: React.FC = () => {
    const { setTitle } = useLayout();
    const navigate = useNavigate();
    const toast = useToast();

    const formRef = useRef<FormHandles>();

    const [commerces, setCommerces] = useState([]);
    const [states, setStates] = useState([] as any);
    const [cities, setCities] = useState([] as any);
    const [efloristaAccounts, setEfloristaAccounts] = useState([] as any);
    const [selectedCities, setSelectedCities] = useState<string[]>([]);

    const [importMethod, setImportMethod] = useState('EFLORISTA');

    const [isLoading, setIsLoading] = useState(false);

    const [selectedDbs, setSelectedDbs] = useState({
        ids: [],
        names: []
    } as any);

    const handleChangeState = useCallback(
        stateId => {
            const state = states.find(item => item.id === stateId);
            setCities(state.cities);
        },
        [states]
    );

    const handleAddSelectedCity = useCallback((cityName: string) => {
        setSelectedCities(oldState => {
            if (!oldState.includes(cityName)) oldState.push(cityName);

            return [...oldState];
        });
    }, []);

    const handleAddSelectedDbsIds = useCallback(
        (dbId: string) => {
            const efloristaDb = efloristaAccounts.find(
                item => item.id === dbId
            );

            setSelectedDbs(oldState => {
                if (!oldState.ids.includes(efloristaDb.id))
                    oldState.ids.push(efloristaDb.id);

                if (!oldState.names.includes(efloristaDb.name))
                    oldState.names.push(efloristaDb.name);

                return { ...oldState };
            });
        },
        [efloristaAccounts]
    );

    const handleRemoveSelectedCity = useCallback((cityName: string) => {
        setSelectedCities(oldState => {
            if (oldState.includes(cityName))
                oldState = oldState.filter(city => city !== cityName);

            return [...oldState];
        });
    }, []);

    const handleRemoveSelectedDbsIds = useCallback(
        (dbId: string) => {
            const efloristaDb = efloristaAccounts.find(
                item => item.id === dbId
            );

            setSelectedDbs(oldState => {
                if (oldState.ids.includes(efloristaDb.id))
                    oldState.ids = oldState.ids.filter(
                        id => id !== efloristaDb.id
                    );

                if (oldState.names.includes(efloristaDb.name))
                    oldState.names = oldState.names.filter(
                        name => name !== efloristaDb.name
                    );

                return { ...oldState };
            });
        },
        [efloristaAccounts]
    );

    const handleSubmit = useCallback(async () => {
        try {
            setIsLoading(true);

            const formData: any = formRef.current?.getData();

            const data = {
                cities: selectedCities,
                commerce_id: formData.commerce_id,
                eflorista_id: formData.eflorista_id,
                dbs_eflorista_ids: selectedDbs.ids
            };

            const { status } = await apiGateway.post(
                '/marketplace/sellers',
                data
            );

            setIsLoading(false);

            if (status === HTTP_RESPONSE.STATUS.CREATED) {
                toast({
                    title: 'Cadastro Realizado',
                    description: '',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });

                navigate('/marketplace/sellers');
            } else {
                toast({
                    title: 'Ocorreu um Problema',
                    description: '',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }
        } catch (e) {
            setIsLoading(false);
        }
    }, [selectedCities, selectedDbs]);

    useEffect(() => {
        setTitle('Marketplace / Sellers / Novo Seller');

        apiGateway.get('/commerces').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setCommerces(data);
            }
        });

        apiGateway.get('/location/states').then(response => {
            const { status, data } = response;
            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setStates(data);
            }
        });

        apiEflorista.get('/stores').then(response => {
            const { status, data } = response;
            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setEfloristaAccounts(data);
            }
        });
    }, []);

    return (
        <Form ref={formRef} style={{ width: '80%', height: '100%' }}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" height="100%" justifyContent="space-between">
                    <Flex width="48%" flexDirection="column">
                        <Flex width="100%" flexDirection="column">
                            <Text
                                fontSize="20px"
                                fontWeight="500"
                                mb="8px"
                                color="blue.500"
                            >
                                Escolha um comércio para vender
                            </Text>
                            <Select
                                name="commerce_id"
                                label="Comércio"
                                placeholder="Selecione um comércio"
                                isRequired
                            >
                                {commerces.map((commerce: any) => (
                                    <option
                                        key={commerce.id}
                                        value={commerce.id}
                                    >
                                        {`${commerce.name} - ${commerce.merchant.document}`}
                                    </option>
                                ))}
                            </Select>
                        </Flex>

                        <Flex width="100%" flexDirection="column" mt="24px">
                            <Text
                                fontSize="20px"
                                fontWeight="500"
                                mb="8px"
                                color="blue.500"
                            >
                                Escolha as cidades atendidas
                            </Text>
                            <Flex width="100%" justifyContent="space-between">
                                <Select
                                    width="48%"
                                    name="state"
                                    label="Estado"
                                    isRequired
                                    placeholder="Selecione"
                                    onChange={e =>
                                        handleChangeState(e.currentTarget.value)
                                    }
                                >
                                    {states.map(state => (
                                        <option key={state.id} value={state.id}>
                                            {state.name}
                                        </option>
                                    ))}
                                </Select>

                                <Select
                                    width="48%"
                                    name="city"
                                    label="Cidade"
                                    isRequired
                                    placeholder="Selecione"
                                    onChange={e =>
                                        handleAddSelectedCity(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    {cities.map(city => (
                                        <option
                                            key={city.id}
                                            value={city.name}
                                            disabled={
                                                selectedCities.includes(
                                                    city.name
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {city.name}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>

                            {selectedCities.length > 0 && (
                                <Flex
                                    width="100%"
                                    flexDirection="column"
                                    mt="16px"
                                >
                                    <Text
                                        color="blue.500"
                                        fontWeight="500"
                                        mb="8px"
                                    >
                                        Cidades Atendidas
                                    </Text>

                                    <Flex
                                        direction={'row'}
                                        width="100%"
                                        flexWrap="wrap"
                                    >
                                        {selectedCities.map(city => (
                                            <Flex
                                                key={city}
                                                backgroundColor="blue.500"
                                                color="white"
                                                borderRadius="2px"
                                                py="4px"
                                                px="16px"
                                                mr="8px"
                                                mb="8px"
                                                position="relative"
                                            >
                                                <Text fontSize="12px">
                                                    {String(city).toUpperCase()}
                                                </Text>

                                                <Flex
                                                    width="16px"
                                                    height="16px"
                                                    position="absolute"
                                                    top="-5px"
                                                    right="-5px"
                                                    backgroundColor="red.500"
                                                    borderRadius="50%"
                                                    cursor={'pointer'}
                                                    onClick={() =>
                                                        handleRemoveSelectedCity(
                                                            city
                                                        )
                                                    }
                                                >
                                                    <Icon as={BiXCircle} />
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>

                        {importMethod === 'EFLORISTA' && (
                            <Flex flexDirection="column" mt="24px">
                                <Text
                                    fontSize="20px"
                                    fontWeight="500"
                                    mb="8px"
                                    color="blue.500"
                                >
                                    Importar Produtos
                                </Text>
                                <Select
                                    name="dbs_eflorista_ids"
                                    label="Base de Dados"
                                    placeholder="Selecione uma base"
                                    isRequired
                                    onChange={e =>
                                        handleAddSelectedDbsIds(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    {efloristaAccounts.map((account: any) => (
                                        <option
                                            key={account.id}
                                            value={account.id}
                                        >
                                            {`${account.name}`}
                                        </option>
                                    ))}
                                </Select>

                                {selectedDbs.names.length > 0 && (
                                    <Flex width="100%" flexDirection="column">
                                        <Text
                                            color="blue.500"
                                            fontWeight="500"
                                            mb="8px"
                                        >
                                            Bases de Dados
                                        </Text>

                                        <Flex
                                            direction={'row'}
                                            width="100%"
                                            flexWrap="wrap"
                                        >
                                            {selectedDbs.names.map(
                                                (dbName, index) => (
                                                    <Flex
                                                        key={dbName}
                                                        backgroundColor="blue.500"
                                                        color="white"
                                                        borderRadius="2px"
                                                        py="4px"
                                                        px="16px"
                                                        mr="8px"
                                                        position="relative"
                                                    >
                                                        <Text fontSize="12px">
                                                            {String(
                                                                dbName
                                                            ).toUpperCase()}
                                                        </Text>

                                                        <Flex
                                                            width="16px"
                                                            height="16px"
                                                            position="absolute"
                                                            top="-5px"
                                                            right="-5px"
                                                            backgroundColor="red.500"
                                                            borderRadius="50%"
                                                            cursor={'pointer'}
                                                            onClick={() =>
                                                                handleRemoveSelectedDbsIds(
                                                                    selectedDbs
                                                                        .ids[
                                                                        index
                                                                    ]
                                                                )
                                                            }
                                                        >
                                                            <Icon
                                                                as={BiXCircle}
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                )
                                            )}
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        )}
                    </Flex>

                    <Flex width="48%" flexDirection="column">
                        <Text
                            fontSize="20px"
                            fontWeight="500"
                            mb="24px"
                            color="blue.500"
                        >
                            Importação de produtos
                        </Text>

                        <Flex flexDirection="column" color="blue.500" mb="8px">
                            <Text fontWeight="500">Modo de Importação</Text>

                            <Flex
                                mt="8px"
                                width="100%"
                                color="blue.500"
                                border="2px solid"
                                borderColor="yellow.500"
                                backgroundColor="yellow.100"
                                px="16px"
                                py="8px"
                                justifyContent="space-between"
                                mb="16px"
                            >
                                <Flex
                                    width="48%"
                                    fontSize="12px"
                                    flexDirection="column"
                                >
                                    <Text fontWeight="500">MODO EFLORISTA</Text>
                                    <Text>
                                        No modo eflorista todos os produtos com
                                        categoria padrão serão importados de
                                        acordo com a conta eflorista
                                        selecionada.
                                    </Text>
                                </Flex>

                                <Flex
                                    width="48%"
                                    fontSize="12px"
                                    flexDirection="column"
                                >
                                    <Text fontWeight="500">MODO VENDEDOR</Text>
                                    <Text>
                                        No modo vendedor você irá importar todos
                                        os produtos de um vendedor ativo dentro
                                        do marketplace.
                                    </Text>
                                </Flex>
                            </Flex>

                            <RadioGroup
                                defaultValue={importMethod}
                                onChange={setImportMethod}
                                colorScheme="green"
                                size="md"
                            >
                                <Stack spacing={4} direction="row">
                                    <Radio
                                        value="EFLORISTA"
                                        borderColor="gray.500"
                                    >
                                        Modo Eflorista
                                    </Radio>
                                    <Radio
                                        value="SELLER"
                                        borderColor="gray.500"
                                    >
                                        Modo Vendedor
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>

                        {importMethod === 'EFLORISTA' && (
                            <Flex flexDirection="column" mt="24px">
                                <Text
                                    fontSize="20px"
                                    fontWeight="500"
                                    mb="8px"
                                    color="blue.500"
                                >
                                    Vincular Eflorista (Receber Pedidos)
                                </Text>
                                <Select
                                    name="eflorista_id"
                                    label="Conta Eflorista"
                                    placeholder="Selecione uma conta"
                                    isRequired
                                >
                                    {efloristaAccounts.map((account: any) => (
                                        <option
                                            key={account.id}
                                            value={account.id}
                                        >
                                            {`${account.name}`}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                <Flex width="100%" justifyContent="flex-end">
                    <Flex
                        backgroundColor="gray.500"
                        width="96px"
                        py="4px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        mr="16px"
                        onClick={() => navigate('/marketplace/sellers')}
                    >
                        <Text fontSize="16px" fontWeight="500" mt="2px">
                            Cancelar
                        </Text>
                    </Flex>

                    <Flex
                        backgroundColor="green.500"
                        width={isLoading ? '46px' : '96px'}
                        py="4px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        onClick={() => handleSubmit()}
                    >
                        {!isLoading && (
                            <Text fontSize="16px" fontWeight="500" mt="2px">
                                Finalizar
                            </Text>
                        )}

                        {isLoading && <Spinner size="sm" />}
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default SellerRegister;
