import React, { useRef, useEffect } from 'react';

import {
    Flex,
    Text,
    Textarea as ChakraTextArea,
    TextareaProps
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import InputBadgeError from '../InputBadgeError';

interface IProps extends TextareaProps {
    name: string;
    label?: string;
    isRequired?: boolean;
    formatChars?: {
        [key: string]: string;
    };
    badgeErrorColor?: string;
    mb?: string | number;
}

const TextArea: React.FC<IProps> = ({
    name,
    label,
    isRequired = false,
    badgeErrorColor = 'red',
    mb = '16px',
    width = '100%',
    ...rest
}) => {
    const inputRef: any = useRef<HTMLTextAreaElement>(null);

    const { fieldName, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    return (
        <Flex width={width} flexDirection="column" mb={mb}>
            {label && (
                <Flex width="100%" textAlign="center" mb="4px">
                    <Text fontSize="14px">{label}</Text>
                    {isRequired && (
                        <Text ml="8px" color="red.500">
                            *
                        </Text>
                    )}
                </Flex>
            )}

            <Flex
                width="100%"
                flexDirection="column"
                position="relative"
                flexWrap="wrap"
            >
                <ChakraTextArea
                    px="12px"
                    ref={inputRef}
                    name={name}
                    isInvalid={!!error}
                    errorBorderColor="red.500"
                    whiteSpace="pre-line"
                    focusBorderColor="none"
                    boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                    {...rest}
                />
                {error && (
                    <InputBadgeError error={error} color={badgeErrorColor} />
                )}
            </Flex>
        </Flex>
    );
};

export default TextArea;
