import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { useLayout } from '../../hooks/layout';

const Header: React.FC = () => {
    const { title } = useLayout();

    return (
        <Flex
            width="100%"
            height="56px"
            justifyContent="center"
            color="blue.800"
            flexDirection="column"
            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
        >
            <Text fontSize="20px" fontWeight="500" ml="24px">
                {title}
            </Text>
        </Flex>
    );
};

export default Header;
