import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import { Flex, Icon, Text } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiGateway from '../../../../shared/apis/gateway';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Form/Input';
import SituationBadge from '../../../../shared/components/List/SituationBadge';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const CitiesIndex: React.FC = () => {
    const { setTitle } = useLayout();
    const navigate = useNavigate();

    const formRef = useRef<FormHandles>();

    const [cities, setCities] = useState([] as any);

    const FIELDS = [
        {
            name: 'Cidade',
            maxWidth: '200px'
        },
        {
            name: 'Situação',
            maxWidth: '100%'
        },
        {
            name: 'Ações',
            maxWidth: '80px'
        }
    ];

    const handleLoadItems = useCallback(async () => {
        apiGateway.get('/marketplace/cities?limit=20').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setCities(data);
            }
        });
    }, []);

    const handleSearch = useCallback(async () => {
        const query = formRef.current?.getFieldValue('query');

        if (query.length > 3) {
            apiGateway
                .get(`/marketplace/cities?name=${query}`)
                .then(response => {
                    const { status, data } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        setCities(data);
                    }
                });
        }
    }, []);

    useEffect(() => {
        setTitle('Marketplace / Cidades / Listagem');

        handleLoadItems();
    }, [handleLoadItems]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex direction={'row'} width="100%" mb="4px">
                <Form ref={formRef}>
                    <Flex width="400px">
                        <Input
                            name="query"
                            placeholder="Pesquisar Cidade"
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                        <Flex
                            backgroundColor="blue.500"
                            color="white"
                            height="32px"
                            alignItems="center"
                            px="16px"
                            cursor="pointer"
                            borderRadius="2px"
                            ml="8px"
                            boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                            onClick={() => handleSearch()}
                        >
                            Buscar
                        </Flex>
                    </Flex>
                </Form>
            </Flex>

            <Flex
                width="100%"
                height="100%"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                flexDirection="column"
            >
                <Flex
                    width="100%"
                    height="32px"
                    fontWeight="500"
                    fontSize="14px"
                    color="blue.800"
                    alignItems="center"
                    justifyContent="space-between"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                >
                    {FIELDS.map(field => (
                        <Flex
                            key={field.name}
                            width="100%"
                            maxWidth={field.maxWidth}
                            justifyContent="center"
                        >
                            <Text>{field.name}</Text>
                        </Flex>
                    ))}
                </Flex>

                <Flex
                    width="100%"
                    flexDirection="column"
                    height="100%"
                    overflow="auto"
                >
                    {cities.map((city, index) => (
                        <Flex
                            key={city.id}
                            width="100%"
                            minHeight="48px"
                            maxHeight="48px"
                            fontSize="14px"
                            color="blue.800"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                index % 2 === 0 ? 'gray.300' : 'gray.100'
                            }
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[0].maxWidth}
                            >
                                <Text>{city.name}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[1].maxWidth}
                            >
                                <SituationBadge
                                    value={
                                        city.has_commerce
                                            ? 'ENABLED'
                                            : 'DISABLED'
                                    }
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[2].maxWidth}
                            >
                                <Flex
                                    backgroundColor={'blue.500'}
                                    color="gray.200"
                                    p="4px"
                                    borderRadius="2px"
                                    title="Editar"
                                    cursor="pointer"
                                    onClick={() =>
                                        navigate(
                                            `/marketplace/cities/${city.slug}`
                                        )
                                    }
                                >
                                    <Icon as={BiEdit} fontSize="16px" />
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CitiesIndex;
