import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        heading: 'CentraNube',
        body: 'CentraNube'
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 500,
        bold: 500,
        extrabold: 500,
        black: 500
    },
    colors: {
        blue: {
            200: '#6B778C',
            300: '#5E6C84',
            400: '#0052CC',
            500: '#42526E',
            600: '#344563',
            700: '#253858',
            800: '#172B4D',
            900: '#091E42'
        },
        green: {
            500: '#36B37E',
            800: '#00875A'
        },
        red: {
            500: '#e74c3c'
        },
        yellow: {
            500: '#FCA311'
        },
        purple: {
            500: '#5222DC'
        },
        gray: {
            100: '#FAFBFC',
            200: '#F4F5F7',
            300: '#EBECF0',
            400: '#DFE1E6',
            500: '#C1C7D0'
        }
    }
});

export default theme;
