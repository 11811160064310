import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Text, useToast } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiGateway from '../../../shared/apis/gateway';
import Form from '../../../shared/components/Form';
import Input from '../../../shared/components/Form/Input';
import Select from '../../../shared/components/Form/Select';
import { HTTP_RESPONSE } from '../../../shared/constants';
import { useLayout } from '../../../shared/hooks/layout';

const CommerceRegister: React.FC = () => {
    const { setTitle } = useLayout();

    const formRef = useRef<FormHandles>(null as any);
    const toast = useToast();

    const [states, setStates] = useState([] as any);
    const [cities, setCities] = useState([] as any);

    const [currentState, setCurrentState] = useState('');

    const navigate = useNavigate();

    const handleChangeState = useCallback(
        stateId => {
            const state = states.find(item => item.id === stateId);
            setCurrentState(state.name);
            setCities(state.cities);
        },
        [states]
    );

    const handleSubmit = useCallback(async () => {
        const data = formRef.current.getData();

        let response = await apiGateway.post('/merchants', {
            name: data.merchant_name,
            document: data.document,
            telephone: data.telephone,
            email: data.email
        });

        if (response.status === HTTP_RESPONSE.STATUS.CREATED) {
            const { id: merchantId } = response.data;

            response = await apiGateway.post('/commerces', {
                name: data.commerce_name,
                state: currentState,
                city: data.city,
                neighborhood: data.neighborhood,
                street: data.street,
                number: data.number,
                complement: data.complement,
                merchant_id: merchantId
            });

            if (response.status === HTTP_RESPONSE.STATUS.CREATED) {
                toast({
                    title: 'Cadastro Realizado',
                    description: '',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });

                navigate('/commerces');
            }
        }
    }, [currentState]);

    useEffect(() => {
        setTitle('Lojas / Novo Loja');

        apiGateway.get('/location/states').then(response => {
            const { status, data } = response;
            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setStates(data);
            }
        });
    }, []);

    return (
        <Form ref={formRef} style={{ width: '80%', height: '100%' }}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" height="100%" justifyContent="space-between">
                    <Flex width="48%" flexDirection="column">
                        <Text
                            fontSize="20px"
                            fontWeight="500"
                            mb="24px"
                            color="blue.500"
                        >
                            Dados da Loja
                        </Text>
                        <Input
                            name="commerce_name"
                            label="Nome da Loja"
                            isRequired
                        />

                        <Flex width="100%" justifyContent="space-between">
                            <Select
                                width="48%"
                                name="state"
                                label="Estado"
                                isRequired
                                placeholder="Selecione"
                                onChange={e =>
                                    handleChangeState(e.currentTarget.value)
                                }
                            >
                                {states.map(state => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </Select>

                            <Select
                                width="48%"
                                name="city"
                                label="Estado"
                                isRequired
                                placeholder="Selecione"
                            >
                                {cities.map(city => (
                                    <option key={city.id} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </Select>
                        </Flex>

                        <Flex width="100%" justifyContent="space-between">
                            <Input
                                width="48%"
                                name="neighborhood"
                                label="Bairro"
                                isRequired
                            />

                            <Input
                                width="48%"
                                name="number"
                                label="Número"
                                isRequired
                            />
                        </Flex>

                        <Input name="street" label="Rua" isRequired />

                        <Input
                            name="complement"
                            label="Complemento"
                            isRequired
                        />
                    </Flex>

                    <Flex width="48%" flexDirection="column">
                        <Text
                            fontSize="20px"
                            fontWeight="500"
                            mb="24px"
                            color="blue.500"
                        >
                            Dados do Proprietário
                        </Text>
                        <Input
                            name="merchant_name"
                            label="Nome do Propritário"
                            isRequired
                        />

                        <Flex justifyContent="space-between">
                            <Input
                                width="48%"
                                name="document"
                                label="CNPJ"
                                isRequired
                            />
                            <Input
                                width="48%"
                                name="telephone"
                                label="Telefone"
                                isRequired
                            />
                        </Flex>
                        <Input name="email" label="E-mail" isRequired />
                    </Flex>
                </Flex>
                <Flex width="100%" justifyContent="flex-end">
                    <Flex
                        backgroundColor="gray.500"
                        width="96px"
                        py="4px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        mr="16px"
                        onClick={() => navigate('/commerces')}
                    >
                        <Text fontSize="16px" fontWeight="500" mt="2px">
                            Cancelar
                        </Text>
                    </Flex>

                    <Flex
                        backgroundColor="green.500"
                        width="96px"
                        py="4px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        onClick={() => handleSubmit()}
                    >
                        <Text fontSize="16px" fontWeight="500" mt="2px">
                            Finalizar
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default CommerceRegister;
