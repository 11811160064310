import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import { useLayout } from '../../hooks/layout';
import Header from '../Header';
import SideMenu from '../SideMenu';

const LayoutPanel: React.FC<any> = ({ component }) => {
    const { user, setUser } = useLayout();

    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('@admin:marketplace');

        if (userData) {
            const { email, password } = JSON.parse(userData);
            setUser({
                email,
                password
            });
        } else {
            navigate('/login');
        }
    }, []);

    return user ? (
        <Flex width="100vw" backgroundColor="gray.100">
            <SideMenu />

            <Flex width="calc(100% - 240px)" flexDirection="column">
                <Header />
                <Flex width="100%" height="calc(100vh - 100px)" p="24px">
                    {component}
                </Flex>
            </Flex>
        </Flex>
    ) : (
        component
    );
};

export default LayoutPanel;
