import { subDays, subMonths } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

import { Flex, Text } from '@chakra-ui/react';

import apiGateway from '../apis/gateway';
import PaymentStatusBadge from '../components/List/PaymentStatusBadge';
import { HTTP_RESPONSE } from '../constants';
import { useLayout } from '../hooks/layout';
import CurrencyUtils from '../utils/CurrencyUtils';
import DateUtils from '../utils/DateUtils';

const Dashboard: React.FC = () => {
    const { setTitle } = useLayout();

    const [orders, setOrders] = useState([] as any);

    const [totals, setTotals] = useState({
        today: 0,
        yesterday: 0,
        month: 0,
        half: 0
    });

    const FIELDS = [
        {
            name: 'Código',
            maxWidth: '100%'
        },
        {
            name: 'Vendedor',
            maxWidth: '100%'
        },
        {
            name: 'Valor Total',
            maxWidth: '80%'
        },
        {
            name: 'Status',
            maxWidth: '80%'
        },
        {
            name: 'Data',
            maxWidth: '160px'
        }
    ];

    const data = [
        ['State', 'Vendas'],
        ['São Paulo', 4],
        ['Bahia', 2],
        ['Rio Grande do Sul', 25],
        ['Santa Catarina', 12]
    ];

    const handleLoadItems = useCallback(() => {
        apiGateway.get('/orders').then(response => {
            const { status, data } = response;

            const updatedTotals = {
                today: 0,
                yesterday: 0,
                month: 0,
                half: 0
            };

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setOrders(data);

                const todayDate = new Date().setHours(0, 0, 0, 0);
                const yesterdayDate = subDays(todayDate, 1).setHours(
                    0,
                    0,
                    0,
                    0
                );

                const month = subMonths(todayDate, 1).setHours(0, 0, 0, 0);
                const halfDate = subMonths(todayDate, 6).setHours(0, 0, 0, 0);

                data.forEach(item => {
                    const orderDate = new Date(item.created_at).setHours(
                        0,
                        0,
                        0,
                        0
                    );

                    if (orderDate === todayDate) {
                        updatedTotals.today += Number(item.total_value);
                    }

                    if (
                        orderDate >= yesterdayDate &&
                        orderDate <=
                            new Date(yesterdayDate).setHours(23, 59, 0, 0)
                    ) {
                        updatedTotals.yesterday += Number(item.total_value);
                    }

                    if (
                        orderDate >= month &&
                        orderDate <= new Date(todayDate).setHours(24, 0, 0, 0)
                    ) {
                        updatedTotals.month += Number(item.total_value);
                    }

                    if (
                        orderDate >= halfDate &&
                        orderDate <= new Date(todayDate).setHours(24, 0, 0, 0)
                    ) {
                        updatedTotals.half += Number(item.total_value);
                    }
                });

                setTotals(updatedTotals);
            }
        });
    }, []);

    useEffect(() => {
        setTitle('Dashboard');
        handleLoadItems();
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="80%" flexDirection="column">
                <Flex
                    width="100%"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    mb="24px"
                >
                    <Flex
                        mr="24px"
                        width="200px"
                        height="88px"
                        backgroundColor="gray.300"
                        color="blue.900"
                        flexDirection="column"
                        px="24px"
                        py="16px"
                        fontWeight="500"
                        borderRadius="2px"
                        boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                    >
                        <Text>Vendas Hoje</Text>
                        <Text color="purple.500" fontSize="18px" mt="8px">
                            {CurrencyUtils.numberToCurrency(totals.today)}
                        </Text>
                    </Flex>

                    <Flex
                        mr="24px"
                        width="200px"
                        height="88px"
                        backgroundColor="gray.300"
                        color="blue.900"
                        flexDirection="column"
                        px="24px"
                        py="16px"
                        fontWeight="500"
                        borderRadius="2px"
                        boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                    >
                        <Text>Vendas Ontem</Text>
                        <Text color="purple.500" fontSize="18px" mt="8px">
                            {CurrencyUtils.numberToCurrency(totals.yesterday)}
                        </Text>
                    </Flex>

                    <Flex
                        mr="24px"
                        width="200px"
                        height="88px"
                        backgroundColor="gray.300"
                        color="blue.900"
                        flexDirection="column"
                        px="24px"
                        py="16px"
                        fontWeight="500"
                        borderRadius="2px"
                        boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                    >
                        <Text>Total Mês</Text>
                        <Text color="purple.500" fontSize="18px" mt="8px">
                            {CurrencyUtils.numberToCurrency(totals.month)}
                        </Text>
                    </Flex>

                    <Flex
                        mr="24px"
                        width="200px"
                        height="88px"
                        backgroundColor="gray.300"
                        color="blue.900"
                        flexDirection="column"
                        px="24px"
                        py="16px"
                        fontWeight="500"
                        borderRadius="2px"
                        boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                    >
                        <Text>Total 6 Meses</Text>
                        <Text color="purple.500" fontSize="18px" mt="8px">
                            {CurrencyUtils.numberToCurrency(totals.half)}
                        </Text>
                    </Flex>
                </Flex>

                <Chart
                    chartEvents={[
                        {
                            eventName: 'select',
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 0) return;
                                const region = data[selection[0].row + 1];
                                console.log('Selected : ' + region);
                            }
                        }
                    ]}
                    options={{
                        region: 'BR',
                        resolution: 'provinces',
                        legend: 'none',
                        colorAxis: {
                            colors: ['#36B37E', '#FCA311', '#e74c3c']
                        },
                        backgroundColor: '#FAFBFC'
                    }}
                    chartType="GeoChart"
                    width="100%"
                    height="400px"
                    data={data}
                />
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                    flexDirection="column"
                    overflow="auto"
                >
                    <Flex
                        width="100%"
                        height="32px"
                        fontWeight="500"
                        fontSize="14px"
                        color="blue.800"
                        alignItems="center"
                        justifyContent="space-between"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                    >
                        {FIELDS.map(field => (
                            <Flex
                                key={field.name}
                                width="100%"
                                maxWidth={field.maxWidth}
                                justifyContent="center"
                            >
                                <Text>{field.name}</Text>
                            </Flex>
                        ))}
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        {orders.map((order, index) => (
                            <Flex
                                key={order.id}
                                width="100%"
                                height="40px"
                                fontSize="14px"
                                color="blue.800"
                                alignItems="center"
                                justifyContent="space-between"
                                backgroundColor={
                                    index % 2 === 0 ? 'gray.300' : 'gray.100'
                                }
                                lineHeight="16px"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth={FIELDS[0].maxWidth}
                                >
                                    <Text>{order.code}</Text>
                                </Flex>
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth={FIELDS[1].maxWidth}
                                >
                                    <Text>{order.seller.commerce.name}</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth={FIELDS[2].maxWidth}
                                >
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            order.total_value
                                        )}
                                    </Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth={FIELDS[3].maxWidth}
                                >
                                    <PaymentStatusBadge value={order.status} />
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth={FIELDS[4].maxWidth}
                                >
                                    <Text>
                                        {DateUtils.format(
                                            order.created_at,
                                            'HH:mm dd/MM/yyyy'
                                        )}
                                    </Text>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Dashboard;
