/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';
import InputMask from 'react-input-mask';

import {
    Flex,
    Input as ChakraInput,
    Text,
    InputProps,
    Box
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import InputBadgeError from '../InputBadgeError';
import { InfoTooltip } from '../InputBadgeError/styles';

interface IProps extends InputProps {
    name: string;
    label?: string;
    isRequired?: boolean;
    mask?: string;
    formatChars?: {
        [key: string]: string;
    };
    maskChar?: string;
    badgeErrorColor?: string;
    mb?: string | number;
    type?: 'default' | 'search';
    typeInput?: string;
    badge?: 'currency' | 'porcentage';
    tooltip?: boolean;
    tooltipMessage?: string;
    onEnterPress?: () => void;
}

const Input: React.FC<IProps> = ({
    name,
    label,
    isRequired = false,
    mask = '',
    formatChars,
    maskChar = '',
    badgeErrorColor = 'red',
    mb = '16px',
    badge = null,
    tooltip = false,
    tooltipMessage = '',
    typeInput = 'text',
    width = '100%',
    onEnterPress = undefined,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },
            clearValue(ref: any) {
                ref.setInputValue('');
            }
        });
    }, [error, fieldName, registerField]);

    return (
        <Flex width={width} flexDirection="column" fontWeight="500" mb={mb}>
            {label && (
                <Flex
                    width="100%"
                    textAlign="center"
                    justifyContent="space-between"
                >
                    <Flex fontSize="14px" fontWeight="400">
                        <Text>{label}</Text>
                        {isRequired && (
                            <Text ml="4px" color="red.500">
                                *
                            </Text>
                        )}
                    </Flex>
                    {tooltip && (
                        <InfoTooltip title={tooltipMessage}>
                            <FiInfo size={16} />
                        </InfoTooltip>
                    )}
                </Flex>
            )}

            <Flex
                width="100%"
                flexDirection="column"
                position="relative"
                flexWrap="wrap"
            >
                {badge && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        backgroundColor="gray.700"
                        width="40px"
                        height="100%"
                        position="absolute"
                        left={badge === 'currency' ? '0' : 'none'}
                        right={badge === 'porcentage' ? '0' : 'none'}
                        zIndex={1000}
                    >
                        <Text color="white" fontSize="14px" fontWeight="500">
                            {badge === 'currency' ? 'R$' : '%'}
                        </Text>
                    </Box>
                )}

                <ChakraInput
                    ref={inputRef}
                    name={name}
                    isInvalid={!!error}
                    errorBorderColor="red.500"
                    autoComplete="new_password"
                    border="2px solid"
                    borderRadius="2px"
                    size="sm"
                    type={typeInput}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && onEnterPress) {
                            onEnterPress();
                        }
                    }}
                    pl={badge === 'currency' ? '48px' : '12px'}
                    pr={badge === 'porcentage' ? '48px' : '12px'}
                    {...rest}
                    focusBorderColor="none"
                    boxShadow="0 1px 3px rgba(0,0,0,0.08), 0 1px 2px rgba(0,0,0,0.08)"
                    _hover={{
                        borderColor: 'none'
                    }}
                />

                {error && (
                    <InputBadgeError error={error} color={badgeErrorColor} />
                )}
            </Flex>
        </Flex>
    );
};

export default Input;
