import React, { useState } from 'react';

import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react';

const Checkbox: React.FC<CheckboxProps> = ({
    name,
    value,
    isChecked,
    size = 'md',
    children,
    ...rest
}) => {
    const [outline, setOutline] = useState('none');

    return (
        <ChakraCheckbox
            name={name}
            size={size}
            borderColor="blue.500"
            colorScheme="green"
            value={value}
            isChecked={isChecked}
            onFocus={() => setOutline('none')}
            style={{
                outline
            }}
            {...rest}
        >
            {children}
        </ChakraCheckbox>
    );
};

export default Checkbox;
