import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

interface SituationBadgeProps {
    value: 'ENABLED' | 'PENDING' | 'DISABLED';
}

const SituationBadge: React.FC<SituationBadgeProps> = ({ value }) => {
    const SITUATION_COLORS = {
        ENABLED: 'green.500',
        PENDING: 'yellow.500',
        DISABLED: 'red.500'
    };

    const SITUATION_TEXT = {
        ENABLED: 'Habilitado',
        PENDING: 'Pendente',
        DISABLED: 'Desabilitado'
    };

    return (
        <Flex
            backgroundColor={SITUATION_COLORS[value]}
            width="98px"
            justifyContent="center"
            color="white"
            py="4px"
            borderRadius="2px"
            fontWeight="500"
        >
            <Text>{SITUATION_TEXT[value]}</Text>
        </Flex>
    );
};

export default SituationBadge;
