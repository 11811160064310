import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Flex, Text, useToast } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiGateway from '../../../../shared/apis/gateway';
import Form from '../../../../shared/components/Form';
import TextArea from '../../../../shared/components/Form/TextArea';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import UploadCityImage from './components/UploadCityImage';

const CityEdit: React.FC = () => {
    const { setTitle } = useLayout();

    const formRef = useRef<FormHandles>(null as any);
    const toast = useToast();

    const { slug } = useParams();

    const [city, setCity] = useState(null as any);

    const navigate = useNavigate();

    const handleSubmit = useCallback(async () => {
        const data = formRef.current.getData();

        const response = await apiGateway.put(
            `/marketplace/cities/${city.id}`,
            {
                description: data.description
            }
        );

        if (response.status === HTTP_RESPONSE.STATUS.SUCCESS) {
            toast({
                title: 'Cidade Atualizada',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            navigate('/marketplace/cities');
        }
    }, [city]);

    useEffect(() => {
        setTitle('Marketplace / Cidade / Editar');

        apiGateway.get(`/marketplace/cities?name=${slug}`).then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setCity(data[0]);
            }
        });
    }, []);

    return (
        city && (
            <Form ref={formRef} style={{ width: '80%', height: '100%' }}>
                <Flex width="100%" flexDirection="column">
                    <Flex width="100%" flexDirection="column">
                        <Text
                            fontSize="20px"
                            fontWeight="500"
                            mb="24px"
                            color="blue.500"
                        >
                            Imagem da Cidade
                        </Text>

                        <Flex
                            width="100%"
                            color="blue.500"
                            borderColor="gray.500"
                            justifyContent="space-between"
                            alignItems="center"
                            mb="16px"
                            flexDirection="column"
                            cursor="pointer"
                        >
                            <UploadCityImage
                                cityId={city.id}
                                defaultImage={city.url_image}
                            />
                        </Flex>

                        <TextArea
                            name="description"
                            label="Texto"
                            height="200px"
                            defaultValue={city.description}
                        />
                    </Flex>

                    <Flex
                        backgroundColor="green.500"
                        width="200px"
                        py="4px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        onClick={() => handleSubmit()}
                    >
                        <Text fontSize="16px" fontWeight="500" mt="2px">
                            Salvar
                        </Text>
                    </Flex>
                </Flex>
            </Form>
        )
    );
};

export default CityEdit;
