import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Flex,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiGateway from '../../../../shared/apis/gateway';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Form/Input';
import Select from '../../../../shared/components/Form/Select';
import TextArea from '../../../../shared/components/Form/TextArea';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const CategoryEdit: React.FC = () => {
    const { setTitle } = useLayout();

    const formRef = useRef<FormHandles>(null as any);
    const toast = useToast();
    const navigate = useNavigate();

    const { id } = useParams();

    const [category, setCategory] = useState(null as any);
    const [categories, setCategories] = useState([] as any);
    const [isEnable, setIsEnable] = useState('1');

    const handleSubmit = useCallback(async () => {
        const formData = formRef.current.getData();

        const { status } = await apiGateway.put(
            `/marketplace/categories/${id}`,
            {
                name: formData.name,
                title: formData.title,
                meta_description: formData.meta_description,
                is_enable: isEnable === '1' ? true : false,
                parent_id: formData.parent_id
            }
        );

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            toast({
                title: 'Cadastro Realizado',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            navigate('/marketplace/categories');
        }
    }, [isEnable]);

    useEffect(() => {
        setTitle('Marketplace / Categoria / Editar');

        apiGateway.get('/marketplace/categories').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) setCategories(data);
        });

        apiGateway.get(`/marketplace/categories/${id}`).then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setCategory(data);
                setIsEnable(data.is_enable ? '1' : '0');
            }
        });
    }, [id]);

    return (
        category && (
            <Form ref={formRef} style={{ width: '80%', height: '100%' }}>
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        height="100%"
                        justifyContent="space-between"
                    >
                        <Flex width="48%" flexDirection="column">
                            <Text
                                fontSize="20px"
                                fontWeight="500"
                                mb="24px"
                                color="blue.500"
                            >
                                Dados da Categoria
                            </Text>

                            <Flex width="100%" justifyContent="space-between">
                                <Input
                                    width="48%"
                                    name="name"
                                    label="Nome da Categoria"
                                    isRequired
                                    defaultValue={category.name}
                                />

                                <Select
                                    width="48%"
                                    name="parent_id"
                                    label="Categoria Pai"
                                    placeholder="Selecione"
                                    defaultValue={category.parent_id}
                                >
                                    {categories.map(category => (
                                        <option
                                            key={category.id}
                                            value={category.id}
                                        >
                                            {category.name}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>

                            <Flex width="100%" justifyContent="space-between">
                                <Input
                                    name="title"
                                    label="Título"
                                    isRequired
                                    defaultValue={category.title}
                                />
                            </Flex>

                            <Flex width="100%" justifyContent="space-between">
                                <TextArea
                                    name="meta_description"
                                    label="Meta Description"
                                    isRequired
                                    defaultValue={category.meta_description}
                                />
                            </Flex>

                            <RadioGroup
                                defaultValue={isEnable}
                                onChange={setIsEnable}
                                colorScheme="green"
                                size="md"
                            >
                                <Stack spacing={4} direction="row">
                                    <Radio value="1" borderColor="gray.500">
                                        Ativo
                                    </Radio>
                                    <Radio value="0" borderColor="gray.500">
                                        Inativo
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                    </Flex>
                    <Flex width="100%" justifyContent="flex-end">
                        <Flex
                            backgroundColor="gray.500"
                            width="96px"
                            py="4px"
                            borderRadius="2px"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            cursor="pointer"
                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                            mr="16px"
                            onClick={() => navigate('/commerces')}
                        >
                            <Text fontSize="16px" fontWeight="500" mt="2px">
                                Cancelar
                            </Text>
                        </Flex>

                        <Flex
                            backgroundColor="green.500"
                            width="96px"
                            py="4px"
                            borderRadius="2px"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            cursor="pointer"
                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                            onClick={() => handleSubmit()}
                        >
                            <Text fontSize="16px" fontWeight="500" mt="2px">
                                Finalizar
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
        )
    );
};

export default CategoryEdit;
