import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { BiEdit, BiInfoCircle, BiTrash } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import { Flex, Icon, Image, Stack, Text } from '@chakra-ui/react';

import apiGateway from '../../../../shared/apis/gateway';
import Checkbox from '../../../../shared/components/Form/Checkbox';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../../shared/utils/DateUtils';

const ProductIndex: React.FC = () => {
    const { setTitle } = useLayout();
    const navigate = useNavigate();

    const [products, setProducts] = useState([] as any);

    const FIELDS = [
        {
            name: '#',
            maxWidth: '80px'
        },
        {
            name: 'Thumb',
            maxWidth: '64px'
        },
        {
            name: 'Código',
            maxWidth: '20%'
        },
        {
            name: 'Produto',
            maxWidth: '100%'
        },
        {
            name: 'Preço Base',
            maxWidth: '80%'
        },
        {
            name: 'Categorias',
            maxWidth: '80px'
        },
        {
            name: 'Criado em',
            maxWidth: '160px'
        },
        {
            name: 'Ações',
            maxWidth: '80px'
        }
    ];

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleChangeSelectedItem = useCallback((value: string) => {
        setSelectedItems(oldState => {
            let list = oldState;

            if (!oldState.includes(value)) {
                list.push(value);
            } else {
                list = list.filter(item => item !== value);
            }

            return [...list];
        });
    }, []);

    const handleLoadItems = useCallback(() => {
        apiGateway.get('/marketplace/products').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) setProducts(data);
        });
    }, []);

    const handleDeleteSelectedItem = useCallback(async () => {
        if (selectedItems.length > 0) {
            const { status } = await apiGateway.delete(
                '/marketplace/products',
                {
                    params: {
                        ids: selectedItems
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params);
                    }
                }
            );

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setSelectedItems([]);
                handleLoadItems();
            }
        }
    }, [selectedItems, handleLoadItems]);

    useEffect(() => {
        setTitle('Marketplace / Produtos / Listagem');
        handleLoadItems();
    }, []);

    return (
        <Flex width="100%" height="100%" flexDirection="column">
            <Stack direction={'row'} width="100%" mb="16px" spacing={4}>
                {selectedItems.length > 0 && (
                    <Flex
                        backgroundColor="red.500"
                        width="40px"
                        height="32px"
                        borderRadius="2px"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        cursor="pointer"
                        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                        onClick={() => handleDeleteSelectedItem()}
                        title="Apagar Registros"
                    >
                        <Icon as={BiTrash} fontSize="18px" />
                    </Flex>
                )}
            </Stack>

            <Flex
                width="100%"
                height="100%"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                flexDirection="column"
            >
                <Flex
                    width="100%"
                    height="32px"
                    fontWeight="500"
                    fontSize="14px"
                    color="blue.800"
                    alignItems="center"
                    justifyContent="space-between"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                    pr="16px"
                >
                    {FIELDS.map(field => (
                        <Flex
                            key={field.name}
                            width="100%"
                            maxWidth={field.maxWidth}
                            justifyContent="center"
                        >
                            <Text>{field.name}</Text>
                        </Flex>
                    ))}
                </Flex>

                <Flex
                    width="100%"
                    height="100%"
                    flexDirection="column"
                    overflowX="auto"
                >
                    {products.map((product, index) => (
                        <Flex
                            key={product.id}
                            width="100%"
                            height="64px"
                            fontSize="14px"
                            color="blue.800"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                index % 2 === 0 ? 'gray.300' : 'gray.100'
                            }
                            lineHeight="16px"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[0].maxWidth}
                            >
                                <Checkbox
                                    onChange={() =>
                                        handleChangeSelectedItem(product.id)
                                    }
                                    isChecked={selectedItems.includes(
                                        product.id
                                    )}
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[1].maxWidth}
                            >
                                <Image
                                    width="56px"
                                    height="56px"
                                    src={product.cover_url_thumb}
                                    boxShadow="0 1px 3px rgba(0,0,0,0.04)"
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[2].maxWidth}
                            >
                                <Text>{product.code}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[3].maxWidth}
                            >
                                <Text flexWrap="wrap">{product.name}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[4].maxWidth}
                            >
                                <Text flexWrap="wrap">
                                    {CurrencyUtils.numberToCurrency(
                                        product.base_price
                                    )}
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[5].maxWidth}
                            >
                                <Icon
                                    as={BiInfoCircle}
                                    fontSize="24px"
                                    title="Ver Categorias"
                                    cursor="pointer"
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[6].maxWidth}
                            >
                                <Text>
                                    {DateUtils.format(product.created_at)}
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[7].maxWidth}
                            >
                                <Flex
                                    backgroundColor={'blue.500'}
                                    color="gray.200"
                                    p="4px"
                                    borderRadius="2px"
                                    title="Editar"
                                    cursor="pointer"
                                    onClick={() =>
                                        navigate(
                                            `/marketplace/products/${product.id}`
                                        )
                                    }
                                >
                                    <Icon as={BiEdit} fontSize="16px" />
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ProductIndex;
