import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Flex, Icon, Image, Text, useToast } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiGateway from '../../../../shared/apis/gateway';
import Form from '../../../../shared/components/Form';
import InputCurrency from '../../../../shared/components/Form/InputCurrency';
import Select from '../../../../shared/components/Form/Select';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import UploadSellerLogo from './components/UploadSellerLogo';

const SellerEdit: React.FC = () => {
    const { setTitle } = useLayout();
    const toast = useToast();
    const navigate = useNavigate();

    const { id } = useParams();

    const formRef = useRef<FormHandles>(null);

    const [seller, setSeller] = useState(null as any);

    const [states, setStates] = useState([] as any);
    const [cities, setCities] = useState([] as any);

    const [selectedCities, setSelectedCities] = useState<string[]>([]);

    const [isHighlighted, setIsHighlighted] = useState(false);

    const handleChangeState = useCallback(
        stateId => {
            const state = states.find(item => item.id === stateId);
            setCities(state.cities);
        },
        [states]
    );

    const handleAddSelectedCity = useCallback(
        (cityName: string) => {
            console.log(cityName);
            setSelectedCities(oldState => {
                if (!oldState.includes(cityName)) oldState.push(cityName);

                return [...oldState];
            });
        },
        [states]
    );

    const handleRemoveSelectedCity = useCallback(
        (cityName: string) => {
            console.log(cityName);
            setSelectedCities(oldState => {
                if (oldState.includes(cityName))
                    oldState = oldState.filter(city => city !== cityName);

                return [...oldState];
            });
        },
        [states]
    );

    const handleSubmit = useCallback(async () => {
        const { status } = await apiGateway.put(`/marketplace/sellers/${id}`, {
            cities: selectedCities,
            is_highlighted: isHighlighted
        });

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            toast({
                title: 'Registro Atualizado',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            navigate('/marketplace/sellers');
        }
    }, [selectedCities, isHighlighted]);

    useEffect(() => {
        setTitle('Marketplace / Seller / Editar');

        apiGateway.get(`/marketplace/sellers/${id}`).then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setSeller(data);
                setIsHighlighted(data.is_highlighted);
                setSelectedCities(data.cities);
            }
        });

        apiGateway.get('/location/states').then(response => {
            const { status, data } = response;
            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setStates(data);
            }
        });
    }, [id]);

    return (
        seller && (
            <Form ref={formRef} style={{ width: '100%', height: '100%' }}>
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        height="100%"
                        justifyContent="space-between"
                    >
                        <Flex width="25%" flexDirection="column">
                            <Flex width="100%" flexDirection="column">
                                <Text
                                    fontSize="20px"
                                    fontWeight="500"
                                    mb="24px"
                                    color="blue.500"
                                >
                                    Logo do Seller
                                </Text>

                                <Flex
                                    width="100%"
                                    color="blue.500"
                                    borderColor="gray.500"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb="16px"
                                    flexDirection="column"
                                    cursor="pointer"
                                >
                                    <UploadSellerLogo
                                        sellerId={seller.id}
                                        defaultImage={seller.logo_url}
                                    />
                                </Flex>
                            </Flex>
                            <Flex width="100%" flexDirection="column">
                                <Text
                                    fontSize="20px"
                                    fontWeight="500"
                                    mb="8px"
                                    color="blue.500"
                                >
                                    Escolha as cidades atendidas
                                </Text>
                                <Flex
                                    width="100%"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                >
                                    <Select
                                        name="state"
                                        label="Estado"
                                        isRequired
                                        placeholder="Selecione"
                                        onChange={e =>
                                            handleChangeState(
                                                e.currentTarget.value
                                            )
                                        }
                                    >
                                        {states.map(state => (
                                            <option
                                                key={state.id}
                                                value={state.id}
                                            >
                                                {state.name}
                                            </option>
                                        ))}
                                    </Select>

                                    <Select
                                        name="city"
                                        label="Estado"
                                        isRequired
                                        placeholder="Selecione"
                                        onChange={e =>
                                            handleAddSelectedCity(
                                                e.currentTarget.value
                                            )
                                        }
                                    >
                                        {cities.map(city => (
                                            <option
                                                key={city.id}
                                                value={city.name}
                                                disabled={
                                                    selectedCities.includes(
                                                        city.name
                                                    )
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {city.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Flex>

                                <Flex width="100%">
                                    <Checkbox
                                        isChecked={isHighlighted}
                                        onChange={() =>
                                            setIsHighlighted(!isHighlighted)
                                        }
                                    >
                                        <Text>Destaque</Text>
                                    </Checkbox>
                                </Flex>

                                {selectedCities.length > 0 && (
                                    <Flex
                                        width="100%"
                                        flexDirection="column"
                                        mt="16px"
                                    >
                                        <Text
                                            color="blue.500"
                                            fontWeight="500"
                                            mb="8px"
                                        >
                                            Cidades Atendidas
                                        </Text>

                                        <Flex width="100%" flexWrap="wrap">
                                            {selectedCities.map(city => (
                                                <Flex
                                                    key={city}
                                                    backgroundColor="blue.500"
                                                    color="white"
                                                    borderRadius="2px"
                                                    py="4px"
                                                    px="16px"
                                                    mr="8px"
                                                    mb="8px"
                                                    position="relative"
                                                >
                                                    <Text fontSize="12px">
                                                        {String(
                                                            city
                                                        ).toUpperCase()}
                                                    </Text>

                                                    <Flex
                                                        width="16px"
                                                        height="16px"
                                                        position="absolute"
                                                        top="-5px"
                                                        right="-5px"
                                                        backgroundColor="red.500"
                                                        borderRadius="50%"
                                                        cursor={'pointer'}
                                                        onClick={() =>
                                                            handleRemoveSelectedCity(
                                                                city
                                                            )
                                                        }
                                                    >
                                                        <Icon as={BiXCircle} />
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                        <Flex
                            width="70%"
                            height="100%"
                            flexDirection="column"
                            alignItems="flex-end"
                        >
                            <Flex flexDirection="column" ml="auto">
                                <Text
                                    fontSize="20px"
                                    fontWeight="500"
                                    mb="24px"
                                    color="blue.500"
                                    justifyContent="flex-end"
                                >
                                    Produtos
                                </Text>

                                <Flex
                                    maxHeight="calc(100vh - 224px)"
                                    flexWrap="wrap"
                                    overflow="auto"
                                >
                                    {seller.ProductSeller.map(
                                        (
                                            { id, price_sale, product },
                                            index
                                        ) => (
                                            <Flex
                                                key={id}
                                                alignItems="center"
                                                justifyContent="center"
                                                backgroundColor="gray.100"
                                                border="2px solid"
                                                borderColor="gray.400"
                                                px="24px"
                                                py="8px"
                                            >
                                                <Flex mr="16px">
                                                    <Image
                                                        src={
                                                            product.cover_url_thumb
                                                        }
                                                        width="40px"
                                                        height="40px"
                                                    />
                                                </Flex>
                                                <Flex
                                                    width="144px"
                                                    lineHeight="16px"
                                                    mr="24px"
                                                >
                                                    <Text fontSize="14px">
                                                        {product.name}
                                                    </Text>
                                                </Flex>

                                                <InputCurrency
                                                    width="128px"
                                                    name={`price[${index}]`}
                                                    defaultValue={CurrencyUtils.numberToCurrency(
                                                        price_sale
                                                    )}
                                                />
                                            </Flex>
                                        )
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" mt="24px" justifyContent="flex-end">
                        <Flex
                            backgroundColor="gray.500"
                            width="96px"
                            py="4px"
                            borderRadius="2px"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            cursor="pointer"
                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                            mr="16px"
                            onClick={() => navigate('/marketplace/sellers')}
                        >
                            <Text fontSize="16px" fontWeight="500" mt="2px">
                                Cancelar
                            </Text>
                        </Flex>

                        <Flex
                            backgroundColor="green.500"
                            width="96px"
                            py="4px"
                            borderRadius="2px"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            cursor="pointer"
                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                            onClick={() => handleSubmit()}
                        >
                            <Text fontSize="16px" fontWeight="500" mt="2px">
                                Finalizar
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
        )
    );
};

export default SellerEdit;
