import React, { useCallback, useEffect, useState } from 'react';
import { BiEdit, BiListPlus } from 'react-icons/bi';
import { FiBox, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Flex, Icon, Stack, Text, useToast } from '@chakra-ui/react';

import apiGateway from '../../../../shared/apis/gateway';
import Checkbox from '../../../../shared/components/Form/Checkbox';
import SituationBadge from '../../../../shared/components/List/SituationBadge';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';
import DateUtils from '../../../../shared/utils/DateUtils';

const SellerIndex: React.FC = () => {
    const { setTitle } = useLayout();
    const navigate = useNavigate();
    const toast = useToast();

    const [sellers, setSellers] = useState([] as any);

    const FIELDS = [
        {
            name: '#',
            maxWidth: '80px'
        },
        {
            name: 'Vendedor',
            maxWidth: '100%'
        },
        {
            name: 'Situação',
            maxWidth: '80%'
        },
        {
            name: 'Região',
            maxWidth: '80%'
        },
        {
            name: 'Criado em',
            maxWidth: '80px'
        },
        {
            name: 'Ações',
            maxWidth: '160px'
        }
    ];

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleChangeSelectedItem = useCallback((value: string) => {
        setSelectedItems(oldState => {
            let list = oldState;

            if (!oldState.includes(value)) {
                list.push(value);
            } else {
                list = list.filter(item => item !== value);
            }

            return [...list];
        });
    }, []);

    const handleLoadItems = useCallback(async () => {
        apiGateway.get('/marketplace/sellers').then(response => {
            const { status, data } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                setSellers(data);
            }
        });
    }, []);

    const handleReimport = useCallback(async (id: string) => {
        apiGateway.get(`/marketplace/sellers/${id}/reimport`).then(response => {
            const { status } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                toast({
                    title: 'Reimportação Concluida',
                    description: '',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });

                handleLoadItems();
            }
        });
    }, []);

    const handleDelete = useCallback(async (id: string) => {
        apiGateway.get(`/marketplace/sellers/${id}/delete`).then(response => {
            const { status } = response;

            if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                toast({
                    title: 'Seller Deletado',
                    description: '',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });

                handleLoadItems();
            }
        });
    }, []);

    useEffect(() => {
        setTitle('Marketplace / Vendedores / Listagem');

        handleLoadItems();
    }, [handleLoadItems]);

    return (
        <Flex width="100%" flexDirection="column">
            <Stack direction={'row'} width="100%" mb="16px" spacing={4}>
                <Flex
                    backgroundColor="green.500"
                    width="96px"
                    height="32px"
                    borderRadius="2px"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    cursor="pointer"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                    onClick={() => navigate('/marketplace/sellers/new')}
                >
                    <Icon as={BiListPlus} fontSize="22px" mr="4px" />
                    <Text fontSize="14px" fontWeight="500">
                        Novo
                    </Text>
                </Flex>
            </Stack>

            <Flex
                width="100%"
                height="100%"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                flexDirection="column"
            >
                <Flex
                    width="100%"
                    height="32px"
                    fontWeight="500"
                    fontSize="14px"
                    color="blue.800"
                    alignItems="center"
                    justifyContent="space-between"
                    boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                >
                    {FIELDS.map(field => (
                        <Flex
                            key={field.name}
                            width="100%"
                            maxWidth={field.maxWidth}
                            justifyContent="center"
                        >
                            <Text>{field.name}</Text>
                        </Flex>
                    ))}
                </Flex>

                <Flex width="100%" flexDirection="column" overflow="auto">
                    {sellers.map((seller, index) => (
                        <Flex
                            key={seller.id}
                            width="100%"
                            height="40px"
                            fontSize="14px"
                            color="blue.800"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                index % 2 === 0 ? 'gray.300' : 'gray.100'
                            }
                            lineHeight="16px"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[0].maxWidth}
                            >
                                <Checkbox
                                    onChange={() =>
                                        handleChangeSelectedItem(seller.id)
                                    }
                                    isChecked={selectedItems.includes(
                                        seller.id
                                    )}
                                />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[1].maxWidth}
                            >
                                <Text>{seller.commerce.name}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[2].maxWidth}
                            >
                                <SituationBadge value={seller.status} />
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[3].maxWidth}
                            >
                                <Text>{seller.commerce.state}</Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[4].maxWidth}
                            >
                                <Text>
                                    {DateUtils.format(seller.created_at)}
                                </Text>
                            </Flex>

                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth={FIELDS[5].maxWidth}
                            >
                                <Flex
                                    backgroundColor={'blue.500'}
                                    color="gray.200"
                                    p="4px"
                                    borderRadius="2px"
                                    title="Editar"
                                    cursor="pointer"
                                    onClick={() =>
                                        navigate(
                                            `/marketplace/sellers/${seller.id}`
                                        )
                                    }
                                >
                                    <Icon as={BiEdit} fontSize="16px" />
                                </Flex>

                                <Flex
                                    ml="8px"
                                    backgroundColor={'white'}
                                    color="gray.200"
                                    p="4px"
                                    borderRadius="2px"
                                    title="Reimportar"
                                    cursor="pointer"
                                    onClick={() => handleReimport(seller.id)}
                                >
                                    <Icon
                                        as={FiBox}
                                        fontSize="16px"
                                        color="blue.500"
                                    />
                                </Flex>

                                <Flex
                                    ml="8px"
                                    backgroundColor={'red.500'}
                                    color="gray.200"
                                    p="4px"
                                    borderRadius="2px"
                                    title="Deletar"
                                    cursor="pointer"
                                    onClick={() => handleDelete(seller.id)}
                                >
                                    <Icon as={FiTrash2} fontSize="16px" />
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SellerIndex;
