import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

interface EnableBadgeProps {
    value: boolean;
}

const EnableBadge: React.FC<EnableBadgeProps> = ({ value }) => {
    return (
        <Flex
            backgroundColor={value ? 'green.500' : 'red.500'}
            width="80px"
            justifyContent="center"
            color="white"
            py="4px"
            borderRadius="2px"
            fontWeight="500"
        >
            <Text>{value ? 'Ativo' : 'Inativo'}</Text>
        </Flex>
    );
};

export default EnableBadge;
