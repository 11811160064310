import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

interface SituationBadgeProps {
    value: 'ENABLED' | 'PENDING' | 'DISABLED';
}

const PaymentStatusBadge: React.FC<SituationBadgeProps> = ({ value }) => {
    const SITUATION_COLORS = {
        APROVADO: 'green.500',
        AGUARDANDO_PAGAMENTO: 'yellow.500',
        NEGADO: 'red.500'
    };

    const SITUATION_TEXT = {
        APROVADO: 'Aprovado',
        AGUARDANDO_PAGAMENTO: 'Ag. Pagamento',
        NEGADO: 'Negado'
    };

    return (
        <Flex
            backgroundColor={SITUATION_COLORS[value]}
            width="112px"
            justifyContent="center"
            color="white"
            py="4px"
            borderRadius="2px"
            fontWeight="500"
        >
            <Text>{SITUATION_TEXT[value]}</Text>
        </Flex>
    );
};

export default PaymentStatusBadge;
