import axios from 'axios';

const apiGateway = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    validateStatus: (status: number) => {
        return status >= 200 && status <= 500;
    }
});

export const apiEflorista = axios.create({
    baseURL: process.env.REACT_APP_API_EFLORISTA,
    validateStatus: (status: number) => {
        return status >= 200 && status <= 500;
    }
});

export default apiGateway;
