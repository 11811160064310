import styled from 'styled-components';

import Tooltip from '../Tooltip';

const Error = styled(Tooltip)`
    display: flex;
    align-items: center;

    svg {
        margin: auto;
    }
    span {
        background: #e41e41;
        color: #fff;
        &::before {
            border-color: #e41e41 transparent;
        }
    }
`;

export const InfoTooltip = styled(Tooltip)`
    display: flex;
    align-items: center;

    svg {
        margin: auto;
    }
    span {
        background: #a0aec0;
        color: #fff;
        &::before {
            border-color: #a0aec0 transparent;
        }
    }
`;

export default Error;
