import React from 'react';
import { BiStore } from 'react-icons/bi';
import { FiGrid, FiSettings, FiShoppingBag } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Flex, Icon, Text } from '@chakra-ui/react';

const SideMenu: React.FC = () => {
    const navigate = useNavigate();

    const MENU = [
        {
            title: 'Dashboard',
            icon: FiGrid,
            link: '/dashboard',
            submenus: []
        },
        {
            title: 'Lojas',
            icon: BiStore,
            link: '/commerces',
            submenus: []
        },
        {
            title: 'Marketplace',
            icon: FiShoppingBag,
            link: '',
            submenus: [
                {
                    title: 'Categorias',
                    link: '/marketplace/categories'
                },
                {
                    title: 'Cidades',
                    link: '/marketplace/cities'
                },
                {
                    title: 'Pedidos',
                    link: '/marketplace/orders'
                },
                {
                    title: 'Produtos',
                    link: '/marketplace/products'
                },
                {
                    title: 'Sellers',
                    link: '/marketplace/sellers'
                }
            ]
        },
        {
            title: 'Configurações',
            icon: FiSettings,
            link: '',
            submenus: []
        }
    ];

    return (
        <Flex
            width="240px"
            height="100vh"
            flexDirection="column"
            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
        >
            <Flex
                width="100%"
                height="80px"
                backgroundColor="green.500"
                flexDirection="column"
                color="white"
                px="24px"
                py="16px"
            >
                <Text fontSize="14px">Olá, seja bem-vindo</Text>
                <Text fontWeight="500" fontSize="20px">
                    Alexandre
                </Text>
            </Flex>

            <Flex
                width="100%"
                height="100%"
                backgroundColor="gray.100"
                flexDirection="column"
                py="24px"
            >
                {MENU.map(item => (
                    <Flex
                        key={item.title}
                        fontSize="14px"
                        color="blue.200"
                        justifyContent="center"
                        flexDirection="column"
                        py="4px"
                        pl="24px"
                        _last={{
                            mt: 'auto'
                        }}
                    >
                        <Flex
                            alignItems="center"
                            _hover={{
                                color: 'blue.500',
                                cursor: 'pointer'
                            }}
                            onClick={() => navigate(item.link)}
                        >
                            <Icon as={item.icon as any} fontSize="16px" />
                            <Text ml="8px" fontWeight="500" mt="2px">
                                {item.title}
                            </Text>
                        </Flex>
                        {item.submenus.map(submenu => (
                            <Flex
                                key={submenu.title}
                                alignItems="center"
                                pl="40px"
                                py="2px"
                                _hover={{
                                    color: 'blue.500',
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate(submenu.link)}
                            >
                                <Text mt="2px">{submenu.title}</Text>
                            </Flex>
                        ))}
                    </Flex>
                ))}
            </Flex>

            <Flex
                width="100%"
                backgroundColor="blue.800"
                mt="auto"
                color="white"
                py="4px"
                justifyContent="center"
            >
                <Text fontSize="12px">Versão 1.0.0</Text>
            </Flex>
        </Flex>
    );
};

export default SideMenu;
