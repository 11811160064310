import React, { useRef, useEffect, useCallback } from 'react';
import { FiInfo } from 'react-icons/fi';

import {
    Flex,
    Input as ChakraInput,
    Text,
    InputProps,
    Box
} from '@chakra-ui/react';
import { useField } from '@unform/core';

import InputBadgeError from '../InputBadgeError';
import { InfoTooltip } from '../InputBadgeError/styles';

interface IProps extends InputProps {
    name: string;
    label?: string;
    isRequired?: boolean;
    mask?: string;
    formatChars?: {
        [key: string]: string;
    };
    maskChar?: string;
    badgeErrorColor?: string;
    mb?: string | number;
    type?: 'default' | 'search';
    typeInput?: string;
    badge?: 'currency' | 'porcentage';
    tooltip?: boolean;
    tooltipMessage?: string;
    onEnterPress?: () => void;
    onChange?: () => void;
}

const InputCurrency: React.FC<IProps> = ({
    name,
    label,
    isRequired = false,
    badgeErrorColor = 'red',
    badge = 'currency',
    typeInput = 'text',
    tooltip = false,
    tooltipMessage = '',
    width = '100%',
    onEnterPress = undefined,
    onChange = null,
    ...rest
}) => {
    const inputRef: any = useRef<HTMLInputElement>(null);

    const { fieldName, error, registerField } = useField(name);

    const handleChange = useCallback(() => {
        const { value } = inputRef.current;

        const formatedValue = parseFloat(
            value
                .replace(/(.*){1}/, '0$1')
                .replace(/[^\d]/g, '')
                .replace(/(\d\d?)$/, '.$1')
        ).toFixed(2);

        inputRef.current.value = Number(formatedValue).toLocaleString('pt-BR', {
            minimumFractionDigits: 2
        });

        if (onChange) {
            onChange();
        }
    }, [onChange]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });

        const formatedValue = parseFloat(
            inputRef.current.defaultValue
                .replace(/(.*){1}/, '0$1')
                .replace(/[^\d]/g, '')
                .replace(/(\d\d?)$/, '.$1')
        ).toFixed(2);

        inputRef.current.value = Number(formatedValue).toLocaleString('pt-BR', {
            minimumFractionDigits: 2
        });
    }, [error, fieldName, handleChange, registerField]);

    return (
        <Flex width={width} flexDirection="column" fontWeight="500">
            {label && (
                <Flex
                    width="100%"
                    textAlign="center"
                    mb="4px"
                    justifyContent="space-between"
                >
                    <Flex>
                        <Text>{label}</Text>
                        {isRequired && (
                            <Text ml="8px" color="red.500">
                                *
                            </Text>
                        )}
                    </Flex>
                    {tooltip && (
                        <InfoTooltip title={tooltipMessage}>
                            <FiInfo size={16} />
                        </InfoTooltip>
                    )}
                </Flex>
            )}

            <Flex
                width="100%"
                flexDirection="column"
                position="relative"
                flexWrap="wrap"
            >
                {badge && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        backgroundColor="gray.600"
                        width="40px"
                        height="100%"
                        position="absolute"
                        left={badge === 'currency' ? '0' : 'none'}
                        right={badge === 'porcentage' ? '0' : 'none'}
                        zIndex={1000}
                    >
                        <Text color="white" fontSize="14px" fontWeight="500">
                            {badge === 'currency' ? 'R$' : '%'}
                        </Text>
                    </Box>
                )}

                <ChakraInput
                    ref={inputRef}
                    name={name}
                    size="sm"
                    isInvalid={!!error}
                    errorBorderColor="red.500"
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="false"
                    type={typeInput}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && onEnterPress) {
                            onEnterPress();
                        }
                    }}
                    textAlign="right"
                    onChange={() => handleChange()}
                    pl={badge === 'currency' ? '48px' : '12px'}
                    pr={badge === 'porcentage' ? '48px' : '12px'}
                    focusBorderColor="none"
                    _focus={{
                        outline: 'none'
                    }}
                    {...rest}
                />
                {error && (
                    <InputBadgeError error={error} color={badgeErrorColor} />
                )}
            </Flex>
        </Flex>
    );
};

export default InputCurrency;
